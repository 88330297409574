.App {
  /* background-color: #30475E; */
  height: 120%;

}

.aboutBox {
  display: block;
  background-color: #222831;
  opacity: 96%;
  height: auto;
  width: 70vw;
  padding-bottom: 10vh;

  position: relative;
  top: -70%;
  left: 50%;
  transform: translate(-50%, -25%);
  border-radius: 25px;

}

.aboutSection {
  color: #F5F5F5;
  background-color: #393E46;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-size: 150%; */
  background-size: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  opacity: 99%;
}

.buttonDiv {
  display: flex;
  justify-content: left;
  padding: 2vh;

  /* sticky */
  position: fixed;
  bottom: 0;
  left: 2vw;
  width: 100%;
  z-index: 2;
}

.button {
  /* back to top button */
  border: none;
  color: #30475E;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 50px;
  transition: transform 0.5s;
  border-radius: 5px;
  transition: 0.4s;
}

.button:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: #EEEEEE;
  background-color: #F05454;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 5%
}

.contactIcon {
  border-radius: 50%;
  transition: transform 0.5s;
  transition: 0.4s;
}

.contactIcon:hover {
  transform: scale(1.1);
  cursor: pointer;
  color: #EEEEEE;
  background-color: #F05454;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 2vh;
  font-size: 1.5em;
  background-color: #EEEEEE;
  color: #222831;
  /* margin-top: 2vh; */
  border-top: solid 1px rgba(0, 0, 0, 0.122);
}

.Header {
  height: 100vh;
  padding-top: 10vh;
  background-color: #222831;
  color: #EEEEEE;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-size: 200%;
  padding-bottom: 2vh;
  animation: fade 0.5s ease-in;
}

.HeaderContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .hidden {
  display: none;
} */

.menuButton {
  opacity: 0;
}

.Nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 7vh;
  justify-content: space-evenly;
  flex-shrink: 1;
  color: #EEEEEE;
  background-color: #222831;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-size: 200%; */
  box-shadow: 1px 15px 20px #00000041;
  /* sticky nav */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.Nav a {
  color: #EEEEEE;
  display: flex;
  transition: transform 0.5s;
  transition: 0.4s;
  padding: 15px 32px;
  border-radius: 5px;
  font-size: 175%;
  text-decoration: none;
}

.Nav h3 {
  display: flex;
  /* align-items: flex-start; */
  transition: transform 0.5s;
  transition: 0.4s;
  padding: 15px 32px;
  border-radius: 5px;
  margin: 0;
  font-size: 225%;
}

.Nav a:hover {
  cursor: pointer;
  color: #ffffff50;
  /* transform: scale(1.1); */
  /* color: #30475E; */
  /* background-color: #F05454; */
}

.Nav h3:hover {
  cursor: pointer;
  color: #ffffff50;

  /* transform: scale(1.1); */
  /* background-color: #F05454; */

}

@media only screen and (max-width: 768px) {

  .aboutBox {

    height: auto;
    width: 90vw;
    padding-bottom: 10vh;
    border-radius: 25px;
    position: relative;
    top: 150%;
    /* left: 50%; */
    transform: translate(-50%);


  }

  .aboutSection {
    height: max-content;
    opacity: 99%;


  }

  .Nav {
    height: 12vh;
  }

  .Nav a {
    display: none;
  }

  .Nav h3 {
    margin-left: 25%;
    font-size: 350%;
    padding-bottom: 2vh;
    padding-left: 0;
    /* transition: transform none; */
  }

  .Nav h3:hover {
    cursor: pointer;
    transform: none;
    color: #F5F5F5;
    background-color: transparent;
  }

  /* .Nav h3:active {
    cursor: pointer;
    transform: none;
    color: #F5F5F5;
    background-color: #30475E;
  } */



  .Nav a {
    margin-left: 0;
  }

  .button {
    display: none;
  }

  .resume-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .resume-column1 {
    list-style: none;
    padding: 0;
    margin-right: 4em;
    margin-bottom: 3em;
  }


  .resume-column1 h3 {
    width: max-content;
    padding: 0;
  }

  .resume-column1 li {
    padding: 0;
    width: max-content;
    /* width: 100vw; */
  }

  .resume-column2 {
    width: max-content;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .video {
    display: none;
  }

}

.photo {
  height: auto;
  width: 100vw;
}



.projectimg {
  /* display: flex;
  flex-wrap: wrap; */
  width: 20vw;
  height: auto;
  background-color: #222831;
  margin: 20px;
  border-radius: 20px;
  /* padding: 10px 5px 0 0; */

}

.drinkup {
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: auto;
  background-color: #222831;
  margin: 20px;
  border-radius: 20px;
  padding: 45px 30px 30px 0;
}

.projectlist {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.projectlist a {
  display: flex;
  justify-content: center;
}

.resume-container {
  display: flex;
  /* flex-wrap: wrap; */
  font-size: smaller;
  background-color: #EEEEEE;
  /* width: max-content; */
}

.resume-column1 {
  padding: 0 5vw;
  width: 30vw;
  position: relative;
}

.resume-column2 {
  padding: 0 5vw;
  width: 70vw;
  position: relative;
  padding-bottom: 3vh;
}

.resume-column1 li {
  padding-left: 5vw;
}

.Subtitle {
  margin-top: 0;
  margin-left: 10%;
  margin-right: 10%;
  letter-spacing: .07em;
  font-size: 25px;

}

.Title {
  display: flex;
  justify-content: center;
  font-size: 50px;
  margin: 0;
  padding: 15vh 0 2vh 0;

}

.HomeTitle {
  display: flex;
  justify-content: center;
}

.HomeSubTitle {
  display: flex;
  font-weight: 100;
  font-size: 35px;
}


@keyframes fade {
  0% {
    opacity: 0;
  }
}

.section {
  /* padding-top: 10vh; */
  height: 90vh;
  background-color: #EEEEEE;
  color: #222831;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-size: 200%;
}


.video {

  position: relative;
  /* overflow: hidden; */
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 20%;
}